import React from 'react';
import { Switch, HashRouter, Route, BrowserRouter as Router} from 'react-router-dom';
import { routes } from './routes';
import Navbar from '../common/Navbar';

import {CircularProgress} from "@mui/material"

export const Routing = ({children}) => {
	const noAuth = localStorage.getItem("user")
	return (
		<>
		<React.Suspense fallback={<div><CircularProgress  color="secondary" /></div>}>
		
		<Router>
		<Navbar />
				{/* {authUser ? <>
						<Navbar />
						<div style={{padding : "2%"}}> */}
						<Switch >
							{routes.map(({ path, Component, exact }) => (
								
									<Route exact path={path} component={Component}  />
								
							))}
							{children}
						</Switch>
						{/* </div>
					</>
					:
					<Login />
				} */}
		</Router>
		</React.Suspense>
		</>
	);
};
