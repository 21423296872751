export const MAIN_LOAD_TRUE = "MAIN_LOAD_TRUE";
export const MAIN_LOAD_FALSE = "MAIN_LOAD_FALSE";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const DISPLAY_MESSAGE = "DISPLAY_MESSAGE";

export const GET_EVENTS = "GET_EVENTS";
export const GET_FACULTY = "GET_FACULTY";
export const GET_OFFICESTAFF = "GET_OFFICESTAFF";
export const GET_COMMIT_MEMBERS = "GET_COMMIT_MEMBERS";
export const GET_CLUB_OFFER = "GET_CLUB_OFFER";
export const GET_HOME_SCREEN = "GET_HOME_SCREEN";

