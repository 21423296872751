import { MAIN_LOAD_FALSE,MAIN_LOAD_TRUE, DISPLAY_MESSAGE,DISPLAY_ERROR,GET_CLUB_OFFER,GET_HOME_SCREEN, GET_EVENTS,GET_FACULTY,GET_COMMIT_MEMBERS, GET_OFFICESTAFF} from './types';

const initialState = {
    is_login: false,
    auth_user: {},
    main_loader : false,
    message : "",
    error : "",
    events : [],
    facilities: [],
    clubCommitteeMember : [],
    clubOfficeStaff : [],
    clubOffers : [],
    homeScreens : []
    
}

const home_reducer = (state = initialState, { type, payload }) => {
    switch(type)
    {
        case MAIN_LOAD_TRUE:
            return Object.assign({}, state, { main_loader: true });
        
        case MAIN_LOAD_FALSE:
            return Object.assign({}, state, { main_loader: false });

        case DISPLAY_MESSAGE:
            return Object.assign({}, state, { message: payload.message });
            
        case DISPLAY_ERROR:
            return Object.assign({}, state, { error: payload.error });

        case GET_EVENTS:
            return Object.assign({}, state, { events: payload });
            
        case GET_FACULTY:
            return Object.assign({}, state, { facilities: payload });

        case GET_OFFICESTAFF:
            return Object.assign({}, state, { clubOfficeStaff: payload });

        case GET_COMMIT_MEMBERS:
            return Object.assign({}, state, {clubCommitteeMember : payload });  
                
        case GET_CLUB_OFFER:
            return Object.assign({}, state, { clubOffers: payload });

        case GET_HOME_SCREEN:
            return Object.assign({}, state, {homeScreens : payload });  

            
        default:  
            return state;
    }
}

export default home_reducer;