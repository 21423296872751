import * as React from 'react';
export const routes = [
	{
		path: '/',
		Component: React.lazy(() => import('../pages/Home')),
		exact: true,
	},
	{
		path: '/home',
		Component: React.lazy(() => import('../pages/Home')),
		exact: true,
	},
	{
		path: '/about-us',
		Component: React.lazy(() => import('../pages/AboutUs')),
		exact: true,
	},
	{
		path: '/events',
		Component: React.lazy(() => import('../pages/Events')),
		exact: true,
	},
	{
		path: '/facility',
		Component: React.lazy(() => import('../pages/Facility')),
		exact: true,
	},
	{
		path: '/gallery',
		Component: React.lazy(() => import('../pages/Gallery')),
		exact: true,
	},
	{
		path: '/membership',
		Component: React.lazy(() => import('../pages/Membership')),
		exact: false,
	},
	{ 
		path: '/news-event',
		Component: React.lazy(() => import('../pages/NewsEvent')),
		exact: true,
	},
	{
		path: '/booking-rooms',
		Component: React.lazy(() => import('../pages/Rooms')),
		exact: true,
	},
	{
		path: '/booking-details',
		Component: React.lazy(() => import('../pages/BookingDetails')),
		exact: true,
	}
]
