import React, {useState} from 'react';
import { alpha, makeStyles } from '@mui/styles';
import {AppBar, Box, List, ListItem, ListItemText, Divider, ListItemIcon, Drawer} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AlarmIcon from '@mui/icons-material/Alarm';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import classNames from "classnames";
import Image from '../../../Images/logo.jpg';
import youtubeIcon from '../../../Images/youtube.png'
import twitterIcon from '../../../Images/twitter.png'
import facebookIcon from '../../../Images/facebook.png'
import {Link} from 'react-router-dom'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { compose } from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom';

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  grow: { 
    flexGrow: 1,
    position : 'sticky',
    top: 0,
    zIndex: 99,
    marginBottom : '20px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'none !important', 
    [theme.breakpoints.down('md')]: {
      display: 'block !important', 
    },
  },
  app_toolbar : {
    // background: '#41662c !important;'
    background : '#FFF !important',
    "& a" : {
      color : '#000 !important',
      textDecoration: "none"
    }
  },
  app_toolbar2 : {
    background: '#448F3C !important',
    color : "#FFF !important",
    "& a" : {
      color : '#FFF !important',
      textDecoration: "none"
    }
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
  title2 :{
    display: 'inline-flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  listName: {
    display: 'flex',
    justifyContent: 'space-between',

    width: '38% !important',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width:'100% !important',
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      width: '100% !important',
      display: 'flex',
    }, 
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      width: '100% !important',
      display: 'flex',
    }, 

    logo :{
      display : "none",
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      },
    }
  },
  listitem:{
    display: "flex",
    alignItems: "center",
    
},
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: "none"
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    marginTop:"90px",
    [theme.breakpoints.up('md')]: {
      display : "none"
    },
  },
  drawerOpen: {
    // marginTop:"56px",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    marginTop:"56px",
    display: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  toolbar : { 
    "& a" : {
      textDecoration : "none",
      color : "inherit"
    }
  },
  active :{
    textAlign: "center",
    borderBottom: '3px solid red',
  },
  navAboutus : {
      display : "flex",
      justifyContent : "space-between",
      paddingTop : "5px",
      [theme.breakpoints.down('lg')]: {
        display: 'none !important', 
      },
  },
  navAboutusContact : {
    display : "flex",
    justifyContent : "space-between",
    minWidth: "300px",
    "& div":{
        display: "flex",
        textAlign: "left",
        paddingLeft: "10px",
        alignItems: "center"
    }
  },
  logo : {
      display : "flex",
      justifyContent : "start",
      alignItems: "center",
    textTransform: "uppercase",
    fontWeight: "900",
    textAlign: "left",
    color: "#5e5e5e"
},
icons : {
    display : "flex",
    alignItems: "center",
    "& div" : {
        marginLeft : "15px"
    }
}
}));

function Navbar(props) {
  
  const [open, setOpen] = useState(false)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  console.log(props.location.pathname)
  const path = props.location.pathname

  const isHome = false; //['/','/home'].includes(props.location["pathname"]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogOut = () =>{
    localStorage.clear()
    window.location.reload()
  }
  const handleDrawerOpen = () => {
    setOpen(!open)
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(false)
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={() => handleLogOut()}>Log Out</MenuItem>
    </Menu>
  );  
  const renderSidebar = (
    <Drawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
          onClose={toggleDrawer("anchor", "false")}
        >
          <div className={classes.toolbar}>
          <List>
             <ListItem button key={"Home"} >
                <ListItemIcon><HomeOutlinedIcon/></ListItemIcon>
                <Link to="/" ><ListItemText primary={"Home"} /></Link>
              </ListItem> 
              <ListItem button key={"aboutUs"}>
                <ListItemIcon><RoomOutlinedIcon/></ListItemIcon>
                <Link to="/about-us"><ListItemText primary={"About Club"} /></Link>
              </ListItem>
              <ListItem button key={"Facilities"}>
                <ListItemIcon><LockOutlinedIcon/></ListItemIcon>
                <Link to="/facility"><ListItemText primary={"Facilities"} /></Link>
              </ListItem>
              <ListItem button key={"membership"}>
                <ListItemIcon><AlarmIcon/></ListItemIcon>
                <Link to="/membership"><ListItemText primary={"Membership"} /></Link>
              </ListItem>
              <ListItem button key={"events"}>
                <ListItemIcon><TouchAppOutlinedIcon/></ListItemIcon>
                <Link to="/events"><ListItemText primary={"Events"} /></Link>
              </ListItem>
              {/* <ListItem button key={"newsevent"}>
                <ListItemIcon><PersonIcon/></ListItemIcon>
                <Link to="/news-event"><ListItemText primary={"News Event"} /></Link>
              </ListItem> */}
              <ListItem button key={"logout"}>
                <ListItemIcon><ExitToAppOutlinedIcon/></ListItemIcon>
                <ListItemText primary={"Logout"} onClick={()=> handleLogOut()}/>
              </ListItem>
          </List>
          </div>
        </Drawer>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      {
        isHome && 
        <Box className={classes.navAboutus}>
            <Box className={classes.logo}> 
                <Box><img src={Image} height={50} style={{ padding: "0px 8px" }} /></Box>
                <Box>Sports Club (R)<br/> Mandya</Box>
            </Box>
            <Box className={classes.navAboutusContact}>
                <Box> 
                    <Box><CallIcon /></Box>
                    <Box>Contact : <br/> +91 9916554852</Box>
                </Box>
                <Box> 
                    <Box><EmailIcon /></Box>
                    <Box>Email : <br/> info@sportsclub@gmail.com</Box>
                </Box>
            </Box>
            <Box className={classes.icons}>  
                <Box><img src={youtubeIcon} /> </Box>
                <Box><img src={twitterIcon} /> </Box>
                <Box><img src={facebookIcon} /> </Box>
            </Box>
        </Box>
      }
      <AppBar position="static" className={isHome ? classes.app_toolbar2  : classes.app_toolbar}>
        <Toolbar >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={()=>handleDrawerOpen()}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title2} variant="h6"  style={{ textAlign: "center", alignItems: 'center' }} noWrap>
            <Box><img src={Image} height={40} style={{ padding: "8px 8px 0 0" }} /></Box>
           Sports Club R Mandya
          </Typography>
          <Box ml={2} className={classes.listName} style={{ align: "right" }}>
           {!isHome &&  <Box className={classes.logo}> 
              <Box><img src={Image} height={50} style={{ padding: "0px 8px" }} /></Box>
              <Box>Sports Club (R)<br/> Mandya</Box>
            </Box>
            }
            <Typography className={classes.listitem} variant="h7" noWrap>
             <Link to="/" className={path =='/' && classes.active}>Home</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
              <Link to="/about-us" className={path =='/about-us' && classes.active}>About Club</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
            <Link to="/facility" className={path =='/facility' && classes.active}>Facilities</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
              <Link to="/membership" className={path =='/membership' && classes.active}>Membership</Link>
            </Typography>
            <Typography className={classes.listitem} variant="h7" noWrap>
             <Link to="/events" className={path =='/events' && classes.active}>Events</Link>
            </Typography>
            {/* <Typography className={classes.listitem} variant="h7" noWrap>
              <Link to="/news-event" className={path =='/news-event' && classes.active}>News Event</Link>
            </Typography> */}
            {/* <Typography className={classes.listitem} variant="h7" noWrap>
              <Link to="/gallery">Gallery</Link>
            </Typography> */}
          </Box>
        </Toolbar>
      </AppBar>
      {renderSidebar}
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default  compose(
  withRouter
)(Navbar)