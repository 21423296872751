import { createStore, combineReducers, applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk'
import {home_reducer} from './home'

const All_reducers = combineReducers({
    home: home_reducer
});


const store = createStore(All_reducers, 
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );

export default store;